import styled, { keyframes } from "styled-components";

const tickerAnimation = keyframes`
  0% { transform: translate3d(0%, 0, 0); }
  100% { transform: translate3d(-200%, 0, 0); }
`;

export const HomeSC = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media only screen and (max-width: 1024px) {
    gap: 2rem;
  }
`;

export const BrandSC = styled.div`
  .brand-sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      text-transform: uppercase;
      font-family: "Canela Trial", sans-serif;
      // font-style: italic;
      font-weight: 300;

      @media only screen and (max-width: 1024px) {
        font-size: 0.8rem;
      }
    }
  }

  .ticker-wrapper {
    overflow: hidden;

    .ticker {
      display: flex;
      list-style-type: none;
      animation: ${tickerAnimation} 30s linear infinite;
      gap: 2rem;

      .ticker-item {
        flex-shrink: 0;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
`;

export const HeroSC = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 4rem;

  .text {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .tagline {
      font-size: 4rem;
      margin-top: 0px;
      margin-bottom: 2rem;
      font-weight: 400;
      text-transform: uppercase;

      span {
        font-family: "Canela Trial", sans-serif;
        font-style: italic;
        font-weight: 200;
      }
    }

    h1 {
      font-family: "Satoshi", sans-serif;
    }

    p {
      font-size: 1rem;
    }
  }

  .media {
    width: 100%;
    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1024px) {
    display: flex;
    gap: 2rem;
    flex-direction: column-reverse;
    .text {
      .tagline {
        font-size: 2rem;
      }
    }

    .service-list {
      grid-template-columns: 1fr;
      gap: 0rem !important;
    }
  }
`;

export const ServicesSC = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    font-size: 2rem !important;
    text-transform: uppercase;
    font-weight: 400;
    text-align: left;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.75rem;
    font-weight: 400;
    margin: 0rem;
    text-transform: uppercase;
  }

  p {
    font-size: 1rem;
  }

  .service-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    .service {
      display: flex;
      flex-direction: column;
      padding: 1rem 0rem;
      text-align: left;
      justify-content: space-between;
      // border-top: 1px solid black;

      a {
        display: flex;
        gap: 1rem;
        align-items: center;
        text-align: left;
        color: #141413;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          .btn-icon {
            transform: translateY(-0.5rem);
          }
        }

        .btn-icon {
          background: #141413;
          border-radius: 999px;
          width: 2rem;
          height: 2rem;
          color: #fdfbf6;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
        }

        span {
          font-size: 1.25rem;
          text-transform: uppercase;
          font-weight: 300;
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    // display: none;
    .service-list {
      grid-template-columns: 1fr;
    }
  }
`;

export const FirstImpressionsSC = styled.div`
  margin: 8rem 0rem;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 4rem;

  .media {
    img {
      width: 100%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4rem;

    h3 {
      font-size: 5rem;
      line-height: 100%;
      margin: 0px;
      font-weight: 500;
      text-transform: uppercase;
    }

    p {
      font-size: 1.25rem;
      margin: 0px;
    }
  }
`;

export const FeaturedWorkSC = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 1280px;
  margin: auto;

  .title {
    h1 {
      font-size: 5rem;
      font-weight: 400;
      margin: 0rem;

      span {
        font-family: "Canela Trial", sans-serif;
        font-weight: 200;
      }
    }
  }

  .featured-works > :nth-child(2),
  .featured-works > :nth-child(4) {
    padding-top: 120px;
  }

  .featured-works {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6rem;

    a {
      text-decoration: none;
      color: #141413;
    }
  }

  @media only screen and (max-width: 1024px) {
    .title {
      h1 {
        font-size: 3rem;
      }
    }

    .featured-works {
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;
    }

    .featured-works > :nth-child(2),
    .featured-works > :nth-child(4) {
      padding-top: 0px;
    }
  }
`;

export const FeaturedWorkItemSC = styled.div`
  width: 100%;

  .text {
    margin-top: 0.5rem;
    h2,
    h3 {
      margin: 0px;
      text-align: left;
      text-transform: uppercase;
      line-height: 160%;
      font-weight: 400;

      span {
        font-family: "Canela Trial", sans-serif;
        font-weight: 200;
      }
    }
  }

  .image {
    overflow: hidden;
    transition: 0.5s;
    &:hover {
      transform: translateY(-0.5rem);
    }
    img {
      width: 100%;
      transition: 0.5s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
`;

export const WhoSC = styled.div`
  display: grid;
  grid-template-columns: 28px 1fr 1fr;
  gap: 4rem;
  text-align: left;
  padding: 4rem 0rem;

  .title {
    border-right: 0.75px solid #141413;
    padding-right: 14px;
  }

  .caption {
    h1 {
      font-weight: 400;
      font-size: 5rem;
      margin: 0rem;
      span {
        font-family: "Canela Trial", sans-serif;
        font-style: italic;
        font-weight: 200;
      }
    }
  }

  .text {
    p {
      font-size: 1.25rem;
      font-family: "Satoshi", sans-serif;
      line-height: 200%;

      span {
        font-size: 2rem;
        font-weight: 400;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 24px 1fr;
    grid-template-areas:
      "title caption"
      "title text";
    gap: 1rem;
    padding: 2rem 0rem;

    .title {
      grid-area: title;
    }

    .caption {
      grid-area: caption;
      h1 {
        font-size: 3rem;
        margin: 0px;
      }
    }

    .text {
      grid-area: text;
      p {
        font-size: 1rem;

        span {
          font-size: 1.5rem;
        }
      }
    }
  }
`;
