import React, { FC } from "react";
import { ReactComponent as SvgWho } from "@assets/svg/photography.svg";
import { ReactComponent as SvgServices } from "@assets/svg/services.svg";
import AnimatedText from "@components/AnimatedText";
import { ServiceSectionSC } from "../Services.styled";
import Jpg1 from "@assets/jpg/square/verez1.jpg";
import Jpg2 from "@assets/jpg/square/verez2.jpg";
import Jpg3 from "@assets/jpg/square/verez9.jpeg";

const services = [
  "Product Photography",
  "Wedding Photography",
  "Jewellery Photography",
  "Budoir Photography",
  "Real Estate Photography",
  "Videography",
];

const images = [Jpg1, Jpg2, Jpg3];

const Photography: FC = () => {
  return (
    <>
      <div className="image-row">
        {images.map((image, index) => (
          <div key={index} className="image-container">
            <img src={image} alt="Verez Visuals" />
          </div>
        ))}
      </div>
      <ServiceSectionSC id="photography">
        <div className="title">
          <SvgWho />
        </div>
        <div className="caption">
          <AnimatedText>
            <h1>
              VEREZ
              <br />
              <span>PHOTOGRAPHY</span>
            </h1>
          </AnimatedText>
          <div className="text">
            <p>
              <span>
                We're not just photographers – let us be your partners in
                capturing the essence of every moment and weaving them into
                compelling narratives.
              </span>
            </p>
            <p>
              Through the lens of Verez Visuals, we specialize in capturing
              breathtaking photos and crafting cinematic videos that ignite a
              profound connection between your brand and its audience. Join us
              on an exciting voyage as we turn your ideas into extraordinary
              visual realities.
            </p>
            <p>
              Allow us to encapsulate your brand's narrative in resonant ways,
              leaving an enduring impression on your viewers. Illuminate your
              brand's essence through the artistry of Verez Visuals, witnessing
              it flourish, thrive, and carve its enduring legacy through the
              magic of exceptional photography and videography.
            </p>
          </div>
        </div>
        <div className="service-title">
          <SvgServices />
        </div>
        <div className="services">
          {services.map((service, index) => (
            <h2 key={index}>{service}</h2>
          ))}
        </div>
      </ServiceSectionSC>
    </>
  );
};

export default Photography;
