import React, { FC } from "react";
import { motion } from "framer-motion";
import { revealText } from "@utils/animation";

interface AnimatedTextProps {
  children: React.ReactNode;
}

const AnimatedText: FC<AnimatedTextProps> = ({ children }) => {
  return (
    <div className="animated-wrapper">
      <motion.div
        initial={revealText.hidden}
        whileInView={revealText.visible}
        viewport={{ once: true }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default AnimatedText;
