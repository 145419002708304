import React, { FC } from "react";
import { NavbarSC } from "./Navbar.styled";
import { Link } from "react-router-dom";
import { ReactComponent as SvgLogo } from "@assets/svg/logo.svg";
import { PrimaryButtonSC } from "@components/Buttons/Buttons.styled";
import { SocialIcon, links } from "@components/Footer/Footer";

export const navLinks = [
  { title: "Home", path: "/" },
  { title: "About", path: "/about" },
  { title: "Services", path: "/services" },
  { title: "Contact", path: "/contact" },
  { title: "Privacy Policy", path: "/privacy-policy" },
  { title: "Terms & Conditions", path: "/terms-and-conditions" },
  { title: "404 - Page Not Found", path: "/404" },
];

const Navbar: FC = () => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const navBarLinks = navLinks.slice(0, 4);

  return (
    <NavbarSC>
      <div className="nav-wrapper">
        <div className="brand">
          <Link to="/">
            <SvgLogo />
          </Link>
        </div>
        <div className="nav-links">
          {navBarLinks.map((link, index) => (
            <Link to={link.path} key={index}>
              <p>{link.title}</p>
            </Link>
          ))}
          <div className="social">
            {links.social.map((social, index) => (
              <a
                key={index}
                className="social-platform"
                href={social.path}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon platform={social.title} />
              </a>
            ))}
          </div>
          <div className="nav-buttons">
            <Link to="/contact">
              <PrimaryButtonSC>Let's Talk</PrimaryButtonSC>
            </Link>
          </div>
        </div>
        <button className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
          <div
            style={{
              transform: menuOpen ? "rotate(45deg)" : "none",
              background: menuOpen ? "#fdfbf6" : "#242422",
            }}
            className="line line-top"
          />
          <div
            style={{
              transform: menuOpen ? "translateX(-1px) rotate(-45deg)" : "none",
              background: menuOpen ? "#fdfbf6" : "#242422",
            }}
            className="line line-bottom"
          />
        </button>
        <div
          style={{ height: menuOpen ? "100vh" : 0 }}
          className="menu-container"
        >
          {menuOpen ? (
            <div className="menu-list">
              {navBarLinks.map((link, index) => (
                <div
                  className="item-container"
                  key={index}
                  style={{ animationDelay: `${index * 0.1}s` }}
                >
                  <div className="item-row">
                    <Link
                      key={index}
                      to={link.path}
                      className="menu-item"
                      style={{
                        animationDelay: `${index * 0.1}s`,
                      }}
                      onClick={() => setMenuOpen(false)}
                    >
                      <p>{link.title}</p>
                    </Link>
                    <p className="number">{index + 1}</p>
                  </div>
                  <div
                    className="line"
                    style={{ animationDelay: `${index * 0.1}s` }}
                  />
                </div>
              ))}
              <div className="social">
                {links.social.map((social, index) => (
                  <a
                    key={index}
                    className="social-platform"
                    href={social.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => setMenuOpen(false)}
                  >
                    <SocialIcon platform={social.title} />
                  </a>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </NavbarSC>
  );
};

export default Navbar;
