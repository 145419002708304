import styled from "styled-components";

export const ContactComponentSC = styled.div`
  margin: 4rem 0rem 4rem 0rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  color: #fdfbf6;

  h1 {
    font-size: 5rem;
    margin: 0rem;
    text-transform: uppercase;
    font-weight: 300;
    span {
      font-family: "Canela Trial", sans-serif;
      font-weight: 200;
    }

    a {
      color: #fdfbf6;
      text-decoration: none;
      transition: 0.5s;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  p {
    margin: 0rem;
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;

    h1 {
      font-size: 1.5rem;
    }

    br {
      display: none;
    }

    p {
      margin: 0rem;
      font-size: 1rem;
    }
  }

  @media only screen and (max-width: 380px) {
    h1 {
      font-size: 1.25rem;
    }

    p {
      margin: 0rem;
      font-size: 0.9rem;
    }
  }
`;

export const ContactFormSC = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "name company"
    "email phone"
    "message message"
    "submit submit";
  gap: 2rem;
  margin-top: 2rem;
  height: fit-content;

  .form-group {
    height: fit-content;
  }

  .form-group:not(.submit) {
    background-color: rgba(17, 17, 17, 0.1);
  }

  .name {
    grid-area: name;
  }

  .company {
    grid-area: company;
  }

  .email {
    grid-area: email;
  }

  .phone {
    grid-area: phone;
  }

  .message {
    grid-area: message;
  }

  .submit {
    grid-area: submit;
  }

  input {
    padding: 1rem;
    width: -webkit-fill-available;
  }

  textarea {
    padding: 1rem;
    width: 100%;
    font-family: "Satoshi", sans-serif;
  }

  input,
  textarea {
    border: 0.75px solid transparent;
    background: transparent;

    &:active,
    &:focus-visible {
      border: 0.75px solid #141413;
      outline: none;
    }
  }

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "name"
      "company"
      "email"
      "phone"
      "message"
      "submit";
  }
`;
