import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Layout from "@components/Layout/Layout";
import { Helmet } from "react-helmet";
import LogoPng from "@assets/png/logo.png";
import JpgVilla from "@assets/jpg/villa-1.jpg";

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta property="og:title" content={"Verez Visuals"} />
        <meta
          property="og:description"
          content={
            "A creative agency based in the UAE specializing in photography, videography, and web design"
          }
        />
        <meta property="og:image" content={LogoPng} />
        <meta property="og:url" content={"https://verezvisuals.com"} />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={"Verez Visuals | Home"} />
        <meta
          name="twitter:description"
          content={
            "A creative agency based in the UAE specializing in photography, videography, and web design"
          }
        />
        <meta name="twitter:image" content={JpgVilla} />
        <meta name="twitter:card" content={JpgVilla} />
      </Helmet>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </div>
  );
}

export default App;
