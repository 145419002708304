import React, { FC } from "react";
import { AboutValuesSC } from "../About.styled";

const AboutValues: FC = () => {
  return (
    <AboutValuesSC>
      <div className="caption">
        <h1>
          In an instant, life's fleeting stories unfold.{" "}
          <span>One shot is all it takes.</span>
        </h1>
      </div>
    </AboutValuesSC>
  );
};

export default AboutValues;
