import Navbar from "@components/Navbar/Navbar";
import Pages from "@pages/index";
import React, { FC } from "react";
import { LayoutSC } from "./Layout.styled";
import Footer from "@components/Footer";

const Layout: FC = () => {
  return (
    <LayoutSC>
      <Navbar />
      <div className="wrapper">
        <Pages />
      </div>
      <Footer />
    </LayoutSC>
  );
};

export default Layout;
