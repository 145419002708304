import styled from "styled-components";

export const ContactSC = styled.div`
  .content {
    margin-top: 6rem;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 4rem;
    text-align: left;

    .form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    h1 {
      font-size: 4rem;
      margin: 0rem;
      text-transform: uppercase;
      font-family: "Canela Trial", sans-serif;
      font-weight: 300;
    }

    p {
      margin: 0rem;
      font-size: 1.5rem;
    }

    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1024px) {
    .content {
      grid-template-columns: 1fr;
      gap: 2rem;
      margin-top: 2rem;

      .text {
        margin-bottom: 2rem;
      }

      h1 {
        font-size: 3rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }
`;
