import styled from "styled-components";

export const FooterSC = styled.div`
  background: #141413;
  padding: 4rem 4rem 1rem 4rem;
  margin-top: 6rem;

  .title {
    width: 100%;
    color: #fdfbf6;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 4rem;
      font-weight: 500;
      max-width: 900px;
      text-transform: uppercase;
    }

    button {
      max-width: 200px;
      margin-top: 4rem;
    }
  }

  .information {
    padding: 4rem 0rem;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    gap: 2rem;
    border-top: 0.75px solid #fdfbf6;

    .logo {
      display: flex;
      gap: 1rem;
      margin-bottom: 2rem;

      svg {
        fill: #fdfbf6;
        height: 40px;
      }
    }

    .company {
      display: flex;
      flex-direction: column;

      p {
        color: #fdfbf6;
        text-align: left;
      }
    }

    .social {
      display: flex;
      color: #fdfbf6;
      gap: 1rem;
      margin-top: 4rem;

      a {
        background: #fdfbf6;
        padding: 0.5rem;
        border-radius: 999px;
        display: flex;
        align-items: center;
        color: #141413;
        font-weight: 400;
        transition: 0.5s;

        &:hover {
          color: rgba(17, 17, 17, 0.5);
          transform: translateY(-0.5rem);
        }
      }
    }

    .links {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      color: #fdfbf6;
      text-align: left;

      h2 {
        font-size: 1.5rem;
        font-weight: 400;
        margin-top: 0rem;
        margin-bottom: 1rem;
        text-transform: uppercase;
      }

      a {
        color: #fdfbf6;
        font-size: 1rem;
        font-weight: 400;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .lower {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    border-top: 0.75px solid #fdfbf6;
    padding-top: 1rem;

    :first-child {
      justify-self: flex-start;
    }

    :last-child {
      justify-self: flex-end;
    }

    p {
      color: #fdfbf6;
    }

    button {
      background: none;
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      cursor: pointer;
      span {
        background: #fdfbf6;
        border-radius: 999px;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
      }
      &:hover {
        span {
          color: rgba(17, 17, 17, 0.5);
          transform: translateY(-0.5rem);
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 12px 12px 6px 12px;

    .information {
      grid-template-columns: 1fr;

      .text-logo {
        width: 100%;
        height: auto !important;
      }
    }

    .lower {
      grid-template-columns: 1fr 1fr;
      font-size: 0.8rem;
      justify-content: space-between;

      .made {
        display: none;
      }
    }
  }
`;
