import styled from "styled-components";

export const LayoutSC = styled.div`
  .wrapper {
    padding: 0rem 4rem;
    position: relative;
    padding-top: 90px;
  }

  @media only screen and (max-width: 1024px) {
    .wrapper {
      padding: 0rem 1rem;
      padding-top: 90px;
    }
  }
`;
