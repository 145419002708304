import styled from "styled-components";

export const ServicesSC = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .intro {
    h1 {
      font-size: 2rem;
      font-weight: 400;
      text-align: left;
      line-height: 160%;

      span {
        color: #969188;
      }
    }
  }

  .image-row {
    display: flex;
    flex-direction: row;
    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1024px) {
    .image-row {
      & > :nth-child(2),
      & > :nth-child(3) {
        display: none;
      }
    }
    .intro {
      h1 {
        font-size: 1rem;
      }
    }
  }
`;

export const ServiceSectionSC = styled.div`
  display: grid;
  grid-template-columns: 28px 1.5fr 28px 0.5fr;
  gap: 4rem;
  text-align: left;
  padding: 4rem 0rem;

  .title,
  .service-title {
    border-right: 0.75px solid #141413;
    padding-right: 14px;
  }

  .caption {
    h1 {
      font-weight: 400;
      font-size: 5rem;
      margin: 0rem;
      span {
        font-family: "Canela Trial", sans-serif;
        font-style: italic;
        font-weight: 200;
      }
    }
  }

  .text {
    p {
      font-size: 1.25rem;
      font-family: "Satoshi", sans-serif;
      line-height: 200%;

      span {
        font-size: 2rem;
        font-weight: 400;
      }
    }
  }

  .services {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h2 {
      font-size: 1.5rem;
      font-weight: 300;
      margin: 0px;
    }
  }

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 24px 1fr;
    grid-template-areas:
      "title caption"
      "service-title services";
    gap: 1rem;
    padding: 2rem 0rem;

    .title {
      grid-area: title;
    }

    .service-title {
      grid-area: service-title;
    }

    .caption {
      grid-area: caption;
      h1 {
        font-size: 3rem;
        margin: 0px;
      }
    }

    .text {
      grid-area: text;
      p {
        font-size: 1rem;

        span {
          font-size: 1.5rem;
        }
      }
    }
  }
`;

export const VideographySC = styled.div``;

export const DigitalSC = styled.div``;

export const ContentCreationSC = styled.div``;
