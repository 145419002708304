import styled, { keyframes } from "styled-components";

const appear = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

const slideIn = keyframes`
0% {
  transform: translateX(-2%);
}
100% {
  transform: translateX(0);
}
`;

const shrink = keyframes`
0% {
  width: 95%;
}
100% {
  width: 90%;
}
`;

export const NavbarSC = styled.div`
  position: fixed;
  width: 100%;
  background: rgba(253, 251, 246, 0.75);
  z-index: 99999;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  margin-bottom: 2rem;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;

  .social {
    display: flex;
    gap: 1rem;

    a {
      transition: 0.5s;
      &:hover {
        opacity: 0.6;
        transform: translateY(-4px);
      }
    }
  }

  .nav-wrapper {
    padding: 1rem 4rem 1rem 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 1024px) {
      padding: 1rem 1rem 1rem 1rem;
    }
  }

  .brand {
    svg {
      height: 40px;
    }
  }

  .nav-links {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: flex-end;

    a {
      color: #141413;
      text-decoration: none;
      text-transform: uppercase;
      transition: 0.5s;

      &:hover {
        text-decoration: underline;
        color: #969188;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .nav-links {
      display: none;
    }
  }

  .menu-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;

    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: #242422;
    color: #fafafa;
    transition: height 0.3s ease;
    z-index: 2;
  }

  .hamburger {
    height: 32px;
    width: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4px;
    background: none;
    border: none;
    z-index: 3;
    gap: 2px;

    @media only screen and (min-width: 1024px) {
      display: none;
    }

    .line {
      height: 2px;
      width: 20px;
      transition: all 0.2s ease;
      margin-bottom: 9px;
    }

    .line-top {
      transform-origin: top left;
    }

    .line-bottom {
      transform-origin: top left;
    }
  }

  .item-container {
    opacity: 0;
    animation: 1s ${appear} forwards;
    margin: auto;

    .item-row {
      display: flex;
      justify-content: space-between;
      width: 90%;
      align-items: center;
      margin: 0 auto;
      font-family: "Canela Trial", sans-serif;
      font-weight: 300;

      .number {
        font-size: 1.5rem;
        text-align: right;

        font-style: italic;
      }
    }
  }

  .menu-item {
    padding: 1rem 0rem;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    animation: 0.5s ${slideIn} forwards;
    color: #fdfbf6;
    text-decoration: none;

    p {
      margin: 0px;
      font-size: 1.5rem;
      text-transform: uppercase;
      font-weight: 400;
    }
  }

  .menu-list {
    padding-top: 4rem;

    .social {
      text-align: center;
      margin-top: 2rem;
      justify-content: center;
    }

    a {
      color: #fdfbf6;
    }

    .line {
      height: 1px;
      background: gray;
      width: 100%;
      margin: 0 auto;
      animation: 0.5s ${shrink} forwards;
    }
  }
`;
