import React, { FC } from "react";
import { ReactComponent as SvgTitle } from "@assets/svg/digital.svg";
import { ReactComponent as SvgServices } from "@assets/svg/services.svg";
import AnimatedText from "@components/AnimatedText";
import { ServiceSectionSC } from "../Services.styled";
import Jpg1 from "@assets/jpg/square/verez7.jpg";
import Jpg2 from "@assets/jpg/square/verez8.jpg";
import Jpg3 from "@assets/jpg/square/verez3.jpg";

const services = [
  "Web Design",
  "Web Development",
  "UI / UX Design",
  "Mobile App Development",
  "E-Commerce",
];

const images = [Jpg1, Jpg2, Jpg3];

const Digital: FC = () => {
  return (
    <>
      <div className="image-row">
        {images.map((image, index) => (
          <div key={index} className="image-container">
            <img src={image} alt="Verez Visuals" />
          </div>
        ))}
      </div>
      <ServiceSectionSC id="digital">
        <div className="title">
          <SvgTitle />
        </div>
        <div className="caption">
          <AnimatedText>
            <h1>
              VEREZ
              <br />
              <span>DIGITAL</span>
            </h1>
          </AnimatedText>
          <div className="text">
            <p>
              <span>
                We transform abstract concepts into immersive web and
                application experiences to shape interfaces that captivate and
                engage users.
              </span>
            </p>
            <p>
              In the digital realm, Verez Visuals offers innovative solutions
              that elevate your brand. From immersive web design to seamless
              user experiences, our creative team blends technology with
              creativity for captivating results.
            </p>
            <p>
              We optimize your online performance using data-driven strategies,
              reaching the right audience at the right time. Whether it's
              interactive apps, engaging email campaigns, or SEO tactics, our
              digital excellence delivers measurable results. Join Verez Visuals
              on a journey of growth and success in the digital frontier.
            </p>
          </div>
        </div>
        <div className="service-title">
          <SvgServices />
        </div>
        <div className="services">
          {services.map((service, index) => (
            <h2 key={index}>{service}</h2>
          ))}
        </div>
      </ServiceSectionSC>
    </>
  );
};

export default Digital;
