import React, { FC } from "react";
import { WhoSC } from "../Home.styled";
import { ReactComponent as SvgWho } from "@assets/svg/who-we-are.svg";
import AnimatedText from "@components/AnimatedText";

const Who: FC = () => {
  return (
    <WhoSC>
      <div className="title">
        <SvgWho />
      </div>
      <div className="caption">
        <AnimatedText>
          <h1>
            VISUAL
            <br />
            <span>STORYTELLERS</span>
          </h1>
        </AnimatedText>
      </div>
      <div className="text">
        <p>
          <span>
            We're not just photographers – let us be your partners in capturing
            the essence of every moment and weaving them into compelling
            narratives.
          </span>
        </p>
        <p>
          From capturing breathtaking photos and crafting cinematic videos to
          curating compelling social media content, we harness the art of
          storytelling to forge meaningful connections between your brand and
          its audience. Join us on an exhilarating journey as we transform your
          concepts into remarkable realities.
        </p>
        <p>
          Let us frame your brand's story in ways that resonate, leaving an
          indelible mark on your audience. Illuminate your brand through the
          lens of Verez Visuals and watch it shine, thrive, and etch its legacy
          through the power of extraordinary photography.
        </p>
      </div>
    </WhoSC>
  );
};

export default Who;
