import styled from "styled-components";

const Button = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 999px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  outline: none;
  border: none;
  padding: 0.5rem 2rem;
`;

export const PrimaryButtonSC = styled(Button)`
  background: #141413;
  color: white;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    opacity: 0.8;
  }
`;

export const SecondaryButtonSC = styled(Button)`
  background: #fdfbf6;
  color: #141413;
`;
