import React, { FC } from "react";
import { HeroSC } from "../Home.styled";
import JpgHeroImg from "@assets/jpg/villa-1.jpg";
import Services from "./Services";

const Hero: FC = () => {
  return (
    <HeroSC>
      <div className="text">
        <h1 className="tagline">
          Redefining the art of <span>visual storytelling</span>
        </h1>
        <Services />
      </div>
      <div className="media">
        <img src={JpgHeroImg} alt="hero-img" />
      </div>
    </HeroSC>
  );
};

export default Hero;
