import React, { FC } from "react";
import { ServicesSC } from "../Home.styled";
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";

const servicesList = [
  {
    title: "Photography",
    description:
      "Elevate your moments with stunning photography that tells a story in every frame.",
    path: "/services#photography",
  },
  {
    title: "Videography",
    description:
      "Transform your vision into reality with our expert videography that tells your story in motion.",
    path: "/services#photography",
  },
  {
    title: "Content Creation",
    description:
      "Fuel your online presence with eye-catching social media content that sparks conversations and drives engagement.",
    path: "/services#content-creation",
  },
  {
    title: "Digital",
    description:
      "Elevate your digital presence with web development that weaves a captivating narrative into every interface.",
    path: "/services#digital",
  },
];

const Services: FC = () => {
  return (
    <ServicesSC>
      <div className="service-list">
        {servicesList.map((service, index) => (
          <div key={index} className="service">
            <h3>{service.title}</h3>
            <p className="gray">{service.description}</p>
            <Link to={service.path}>
              <div className="btn-icon">
                <FiArrowUpRight />
              </div>
              <p className="btn-text">About {service.title}</p>
            </Link>
          </div>
        ))}
      </div>
    </ServicesSC>
  );
};

export default Services;
