import React, { FC } from "react";
import { BrandSC } from "../Home.styled";
import { ReactComponent as SvgTextLogo } from "@assets/svg/text-logo-regular.svg";
import AnimatedText from "@components/AnimatedText";

const Brand: FC = () => {
  return (
    <BrandSC>
      <AnimatedText>
        <SvgTextLogo />
      </AnimatedText>
      <div className="brand-sub">
        <p>Creative Agency</p>
        <p>Based in the U.A.E</p>
      </div>
    </BrandSC>
  );
};

export default Brand;
