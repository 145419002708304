import React, { FC } from "react";
import { AboutSC } from "./About.styled";
import FadingPageTransition from "@components/FadingPageWrapper";
import { ReactComponent as SvgTextLogo } from "@assets/svg/about-title.svg";
import AnimatedText from "@components/AnimatedText";
import AboutMain from "./components/AboutMain";
import AboutValues from "./components/AboutValues";
import Who from "@pages/Home/components/Who";

const About: FC = () => {
  return (
    <FadingPageTransition>
      <AboutSC>
        <AnimatedText>
          <SvgTextLogo />
        </AnimatedText>
        <div className="content">
          <AboutMain />
          <AboutValues />
          <Who />
        </div>
      </AboutSC>
    </FadingPageTransition>
  );
};

export default About;
