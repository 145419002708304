import React, { FC } from "react";
import { ReactComponent as SvgTitle } from "@assets/svg/content-creation.svg";
import { ReactComponent as SvgServices } from "@assets/svg/services.svg";
import AnimatedText from "@components/AnimatedText";
import { ServiceSectionSC } from "../Services.styled";
import Jpg1 from "@assets/jpg/square/verez4.jpg";
import Jpg2 from "@assets/jpg/square/verez5.jpg";
import Jpg3 from "@assets/jpg/square/verez6.jpg";
const services = ["Posts", "Reels", "Strategy", "Digital Ads", "SEO"];
const images = [Jpg1, Jpg2, Jpg3];

const ContentCreation: FC = () => {
  return (
    <>
      <div className="image-row">
        {images.map((image, index) => (
          <div key={index} className="image-container">
            <img src={image} alt="Verez Visuals" />
          </div>
        ))}
      </div>
      <ServiceSectionSC id="content-creation">
        <div className="title">
          <SvgTitle />
        </div>
        <div className="caption">
          <AnimatedText>
            <h1>
              VEREZ
              <br />
              <span>CONTENT</span>
            </h1>
          </AnimatedText>
          <div className="text">
            <p>
              <span>
                Let us be your guide in seizing the very essence of each instant
                and threading them into captivating stories.
              </span>
            </p>
            <p>
              In the realm of social media, seizing attention is essential. At
              Verez Visuals, we specialize in crafting compelling social media
              content that thrusts your brand into the spotlight of online
              platforms. From captivating posts to dynamic reels, our team
              combines creativity and strategic prowess to shape social media
              experiences that enthrall and engage.
            </p>
            <p>
              We leverage data-driven strategies to optimize your social
              performance, ensuring your brand connects with the ideal audience
              at the perfect moment. Whether it's curating interactive posts,
              devising captivating reel campaigns, or implementing effective
              social media strategies, we execute social excellence that yields
              tangible outcomes.
            </p>
          </div>
        </div>
        <div className="service-title">
          <SvgServices />
        </div>
        <div className="services">
          {services.map((service, index) => (
            <h2 key={index}>{service}</h2>
          ))}
        </div>
      </ServiceSectionSC>
    </>
  );
};

export default ContentCreation;
