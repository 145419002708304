import React, { FC, useEffect } from "react";
import { ContactSC } from "./Contact.styled";
import AnimatedText from "@components/AnimatedText";
import { ReactComponent as SvgTitle } from "@assets/svg/contact-title.svg";
import FadingPageTransition from "@components/FadingPageWrapper";
import JpgImage from "@assets/jpg/angelina-2.jpg";
import FadeImageAnimation from "@components/FadeImageAnimation";
// import ContactForm from "@components/Contact/components/ContactForm";

interface HubspotContactFormProps {
  region: string;
  portalId: string;
  formId: string;
}

const HubspotContactForm: FC<HubspotContactFormProps> = ({
  region,
  portalId,
  formId,
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.hbspt) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.hbspt.forms.create({
          region: region,
          portalId: portalId,
          formId: formId,
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return (
    <div>
      <div id="hubspotForm"></div>
    </div>
  );
};

const Contact: FC = () => {
  return (
    <FadingPageTransition>
      <ContactSC>
        <AnimatedText>
          <SvgTitle />
        </AnimatedText>
        <div className="content">
          <FadeImageAnimation src={JpgImage} alt="Image" />
          <span className="form">
            <div className="text">
              <h1 style={{ marginBottom: "1rem" }}>Let's Talk!</h1>
              <p>
                Email us at{" "}
                <a href="mailto:contact@verezvisuals.com">
                  contact@verezvisuals.com
                </a>
              </p>
              <p>Or reach out using the form below.</p>
            </div>
            <HubspotContactForm
              region="na1"
              portalId="43133870"
              formId="94c5c8fb-5f05-4f7d-a8de-56b19dab141d"
            />
            {/* <ContactForm /> */}
            <p>
              We're happy to make time for you no matter where you are in the
              world.
            </p>
          </span>
        </div>
      </ContactSC>
    </FadingPageTransition>
  );
};

export default Contact;
