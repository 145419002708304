import React, { FC } from "react";
import { ReactComponent as SvgLogo } from "@assets/svg/logo.svg";
import { ReactComponent as SvgTextLogo } from "@assets/svg/text-logo-regular.svg";
import { FooterSC } from "./Footer.styled";
import { Link } from "react-router-dom";
import {
  FaChevronUp,
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaPinterestP,
} from "react-icons/fa";
import ContactComponent from "@components/Contact";

export const links = {
  pages: [
    { title: "Home", path: "/" },
    { title: "About", path: "/about" },
    { title: "Services", path: "/services" },
    { title: "Contact", path: "/contact" },
  ],
  social: [
    {
      title: "Instagram",
      path: "https://www.instagram.com/verez_visuals/",
    },
    {
      title: "YouTube",
      path: "https://youtube.com/@Verez_visuals",
    },
    {
      title: "Pinterest",
      path: "https://www.pinterest.com/verezvisuals/",
    },
  ],
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const SocialIcon = ({ platform }: { platform: string }) => {
  switch (platform) {
    case "Instagram":
      return <FaInstagram />;
    case "Facebook":
      return <FaFacebookF />;
    case "YouTube":
      return <FaYoutube />;
    case "Pinterest":
      return <FaPinterestP />;
    default:
      return <FaInstagram />;
  }
};

const Footer: FC = () => {
  return (
    <FooterSC>
      <div className="contact">
        <ContactComponent />
      </div>
      <div className="information">
        <div className="company">
          <div className="logo">
            <SvgLogo />
            <SvgTextLogo className="text-logo" />
          </div>

          <p>Verez Visuals Dubai</p>
          <a
            href="mailto:contact@verezvisuals.com"
            style={{ textDecoration: "none" }}
          >
            <p>contact@verezvisuals.com</p>
          </a>
          <p>Dubai, UAE</p>
          <div className="social">
            {links.social.map((social, index) => (
              <a
                key={index}
                className="social-platform"
                href={social.path}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon platform={social.title} />
              </a>
            ))}
          </div>
        </div>
        <div className="links">
          <h2>Pages</h2>
          {links.pages.map((page, index) => (
            <Link key={index} to={page.path}>
              {page.title}
            </Link>
          ))}
        </div>
        <div className="links">
          <h2>Social</h2>
          {links.social.map((social, index) => (
            <a
              key={index}
              href={social.path}
              target="_blank"
              rel="noopener noreferrer"
            >
              {social.title}
            </a>
          ))}
        </div>
      </div>
      <div className="lower">
        <Link to="/" style={{ textDecoration: "none" }}>
          <p>© Verez Visuals</p>
        </Link>

        <p className="made">MADE WITH ❤️ IN THE UAE</p>

        <button onClick={scrollToTop}>
          <span>
            <FaChevronUp />
          </span>
          <p>Scroll to Top</p>
        </button>
      </div>
    </FooterSC>
  );
};

export default Footer;
