import React, { FC } from "react";
import { ServicesSC } from "./Services.styled";
import FadingPageTransition from "@components/FadingPageWrapper";
import AnimatedText from "@components/AnimatedText";
import { ReactComponent as SvgTextLogo } from "@assets/svg/our-services.svg";
import Photography from "./components/Photography";
import ContentCreation from "./components/ContentCreation";
import Digital from "./components/Digital";
import ScrollToHashElement from "./components/ScrollToHashElement";

const Services: FC = () => {
  return (
    <FadingPageTransition>
      <ScrollToHashElement />
      <ServicesSC>
        <AnimatedText>
          <SvgTextLogo />
        </AnimatedText>
        <div className="intro">
          <h1>
            <span>Enter the world of Verez Visuals: </span>An artistic sanctuary
            where the fusion of passion and innovation gives rise to exceptional
            photography, videography, and content creation. Our dedication to
            visionary storytelling and strategic creativity sets the stage for
            capturing moments that resonate and crafting content that inspires.
          </h1>
        </div>
        <Photography />
        <ContentCreation />
        <Digital />
      </ServicesSC>
    </FadingPageTransition>
  );
};

export default Services;
