import React, { FC } from "react";
import { motion } from "framer-motion";
import { fadePage } from "@utils/animation";

interface AnimatedTextProps {
  children: React.ReactNode;
}

const FadingPageTransition: FC<AnimatedTextProps> = ({ children }) => {
  return (
    <div className="animated-wrapper">
      <motion.main
        initial={fadePage.initial}
        animate={fadePage.animate}
        exit={fadePage.exit}
        transition={fadePage.transition}
      >
        {children}
      </motion.main>
    </div>
  );
};

export default FadingPageTransition;
