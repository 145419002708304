import React, { FC } from "react";
import { FeaturedWorkItemSC, FeaturedWorkSC } from "../Home.styled";
import JpgLeskes5 from "@assets/jpg/leskes-5.jpg";
import JpgASAP from "@assets/jpg/asap-grooming.jpg";
import JpgBali1 from "@assets/jpg/villa-2.jpeg";
import JpgCouples1 from "@assets/jpg/couples-1.jpg";
import AnimatedText from "@components/AnimatedText";
import FadeImageAnimation from "@components/FadeImageAnimation";
import { Link } from "react-router-dom";

const featuredWorks = [
  {
    client: "Leskes Jewellers",
    type: "Jewellery Photography",
    img: JpgLeskes5,
    alt: "Leskes Jewellers",
    url: "https://www.leskesjewellers.com.au/",
  },
  {
    client: "Casa Meraki",
    type: "Real Estate Photography",
    img: JpgBali1,
    alt: "Casa Meraki",
    url: "https://www.airbnb.com/rooms/789514004599141433",
  },
  {
    client: "Sunshine Coast, Australia",
    type: "Couples Photography",
    img: JpgCouples1,
    alt: "Couples Photography",
    url: "#",
  },
  {
    client: "ASAP Dog Grooming Australia",
    type: "Brand Photography",
    img: JpgASAP,
    alt: "ASAP Grooming",
    url: "http://asapdoggrooming.com.au/",
  },
];

const FeaturedWork: FC = () => {
  return (
    <FeaturedWorkSC>
      <div className="title">
        <AnimatedText>
          <h1>
            FEATURED
            <br />
            <span>WORKS</span>
          </h1>
        </AnimatedText>
      </div>
      <div className="featured-works">
        {featuredWorks.map((image, index) => (
          <Link
            key={index}
            to={image.url}
            target={image.url !== "#" ? "_blank" : ""}
            rel="noopener noreferrer"
          >
            <FeaturedWorkItemSC>
              <div className="image">
                <FadeImageAnimation src={image.img} alt={image.alt} />
              </div>
              <div className="text">
                <h3 className="gray">
                  <span>{image.type}</span>
                </h3>
                <h2>{image.client}</h2>
              </div>
            </FeaturedWorkItemSC>
          </Link>
        ))}
      </div>
    </FeaturedWorkSC>
  );
};

export default FeaturedWork;
