import React, { FC } from "react";
import { ContactComponentSC } from "./ContactComponent.styled";
import AnimatedText from "@components/AnimatedText";

const ContactComponent: FC = () => {
  return (
    <ContactComponentSC>
      <h1>
        Let's talk
        <br />
        <span>
          <AnimatedText>
            <a href="mailto:contact@verezvisuals.com">
              contact@verezvisuals.com
            </a>
          </AnimatedText>
        </span>
      </h1>
      <p>
        We love what we do are always happy to make time for you. <br />
        No matter where you are in the world.
      </p>
    </ContactComponentSC>
  );
};

export default ContactComponent;
