import React, { FC } from "react";
import { motion } from "framer-motion";
import { fadeImg } from "@utils/animation";

interface AnimatedTextProps {
  src: string;
  alt: string;
}

const FadeImageAnimation: FC<AnimatedTextProps> = ({ src, alt }) => {
  return (
    <motion.img
      initial={fadeImg.hidden}
      whileInView={fadeImg.visible}
      viewport={{ once: true }}
      src={src}
      alt={alt}
    />
  );
};

export default FadeImageAnimation;
