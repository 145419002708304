import React, { FC } from "react";
import { AboutMainSC } from "../About.styled";
import FadeImageAnimation from "@components/FadeImageAnimation";
import JpgAngelina from "@assets/jpg/angelina.jpg";
import { PrimaryButtonSC } from "@components/Buttons/Buttons.styled";
import { Link } from "react-router-dom";

const AboutMain: FC = () => {
  return (
    <AboutMainSC>
      <div>
        <h1>
          Seizing attention swiftly is an art in itself.
          <span>”</span>
        </h1>
        <p className="gray">
          At Verez Visuals, we recognize the true essence of impactful
          photography. With each image, we endeavor to ignite emotions, inspire
          curiosity, and evoke a sense of wonder. When it comes to photography,
          every frame must speak volumes.
        </p>
        <p>- Angelina Verezub, Founder & Creative Director</p>
        <Link to="/services">
          <PrimaryButtonSC style={{ maxWidth: 200, marginTop: "2rem" }}>
            Our Services
          </PrimaryButtonSC>
        </Link>
      </div>
      <FadeImageAnimation src={JpgAngelina} alt="angelina-img" />
    </AboutMainSC>
  );
};

export default AboutMain;
