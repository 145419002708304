import React, { FC } from "react";
import { HomeSC } from "./Home.styled";
import Brand from "./components/Brand";
import Hero from "./components/Hero";
import FeaturedWork from "./components/FeaturedWork";
import FadingPageTransition from "@components/FadingPageWrapper";
import Who from "./components/Who";

const Home: FC = () => {
  return (
    <FadingPageTransition>
      <HomeSC>
        <Brand />
        <Hero />
        <Who />
        <FeaturedWork />
      </HomeSC>
    </FadingPageTransition>
  );
};

export default Home;
