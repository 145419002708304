import styled from "styled-components";

export const AboutSC = styled.div`
  .content {
    margin: 4rem 0rem;
  }
`;

export const AboutMainSC = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  text-align: left;

  h1 {
    font-size: 4rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 0px;
    &:before {
      content: "“";
      display: flex;
      line-height: 1;
      position: relative;
      top: 0.3em;
    }

    span {
      font-family: Neue Haas Grotesk Text Pro, Roboto, sans-serif;
    }
  }

  p {
    font-size: 1.5rem;
  }

  img {
    width: 100%;
  }

  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column-reverse;

    h1 {
      font-size: 3rem;
    }

    p {
      font-size: 1.25rem;
    }
  }
`;

export const AboutValuesSC = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 4rem;
  text-align: left;
  padding: 4rem 0rem;

  .title {
    border-right: 0.75px solid #141413;
    padding-right: 14px;
  }

  .caption {
    h1 {
      font-weight: 400;
      font-size: 5rem;
      margin: 0rem;

      &:before {
        content: "“";
        display: flex;
        line-height: 1;
        position: relative;
        top: 0.3em;
      }

      text-transform: uppercase;
      span {
        font-family: "Canela Trial", sans-serif;
        font-style: italic;
        font-weight: 200;
      }
    }
  }

  .text {
    p {
      font-size: 1.25rem;
      font-family: "Satoshi", sans-serif;
      line-height: 200%;

      span {
        font-size: 2rem;
        font-weight: 400;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 2rem 0rem;

    .caption {
      h1 {
        font-size: 3rem;
        margin: 0px;
      }
    }

    .text {
      p {
        font-size: 1rem;

        span {
          font-size: 1.5rem;
        }
      }
    }
  }
`;
